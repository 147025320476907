<template>
    <div class="admin-content">
        <div class="main-wrapper">
            <div class="admin-top">
                <div class="top-left">
                    <span class="left-title">公告管理</span>
                </div>
                <div class="top-right">
                    <el-button class="el-button el-button--primary" type="primary" @click="addData">创建</el-button>
                </div>
            </div>
            <el-table :data="tableData" class="college-table" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#F5F5F5',}" :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
                <el-table-column label="标题" prop="title" align="center"></el-table-column>
                <el-table-column label="时间" prop="create_time" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #666" @click="lookData(scope.row)">查看</el-button>
                        <el-button type="text" style="color: #1E33E4" @click="editData(scope.row)">编辑</el-button>
                        <el-button type="text" style="color: #F90B32" @click="deleData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="tableDataPages.currentPageNum"
                    :page-size="tableDataPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="tableDataPages.total"
                    @current-change="tableDataCurrentChange">
            </el-pagination>
            <el-dialog :title="TitleType" :visible.sync="dialogAdd" center width="30%" @close="resetForm()" @opened="openAddClassifies" :close-on-click-modal="false">
                <el-form :model="formData" ref="formData" :rules="rules" label-position="left">
                    <el-form-item label="公告标题" :label-width="formLabelWidth" prop="title">
                        <el-input v-model="formData.title" ref="titleInput" autocomplete="off" placeholder="请输入公告名称" style="width: 80%;" ></el-input>
                    </el-form-item>
                    <el-form-item label="公告内容" :label-width="formLabelWidth" prop="content">
                        <el-input type="textarea" rows="6" v-model="formData.content" ref="contentInput" autocomplete="off" placeholder="请输入公告内容" style="width: 80%;" ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="dialogAdd = false">取 消</el-button>
                    <el-button size="medium" type="primary" @click="addToForm('formData')">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="查看平台公告" :visible.sync="dialogLook" center width="30%" :close-on-click-modal="false">
                <div class="bulletin-box">
                    <div class="bulletin-title">
                        <span>公告标题</span>
                        <span>{{lookTitle}}</span>
                    </div>
                    <div class="bulletin-content">
                        <span>公告内容</span>
                        <span>{{lookContent}}</span>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="dialogLook = false">取 消</el-button>
                    <el-button size="medium" type="primary" @click="dialogLook = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
    export default {
        name: "AnnouncementManage",
        data(){
            return {
                tableData:[],
                tableDataPages:{
                    currentPageNum:1,
                    eachPageNum:10,
                    total:0
                },
                TitleType:'',
                dialogAdd:false,
                dialogLook:false,
                formData:{
                    id:'',
                    title:'',
                    content:''
                },
                rules:{
                    title:[
                        { required: true, message: '请输入公告标题', trigger: 'blur' },
                        { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
                    ],
                    content:[
                        { required: true, message: '请输入公告内容', trigger: 'blur' },
                        { min: 3, max: 150, message: '长度在 3 到 150 个字符', trigger: 'blur' }
                    ]
                },
                formLabelWidth: '80px',
                distinguishBtn:'', //创建或编辑
                lookTitle:'',
                lookContent:''
            }
        },
        mounted() {
            this.getList()
        },
        methods:{
            getList(){
              let param = {
                  type:'page',
                  page:this.tableDataPages.currentPageNum,
                  limit:this.tableDataPages.eachPageNum
              }
              this.$http.axiosGetBy(this.$api.pa_list, param, res=>{
                  if(res.code === 200){
                      this.tableData = res.data.data;
                      this.tableDataPages.total = res.data.total;
                  } else {
                      this.$message.warning(res.message)
                  }
              })
            },
            //保存
            addToForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        let formData = new FormData();
                        formData.append('title',this.formData.title);
                        formData.append('content',this.formData.content);
                        if(this.distinguishBtn === 'edit'){
                            formData.append('id',this.formData.id)
                        }
                        this.$http.axiosPost(this.$api.pa_save, formData, res=>{
                            if(res.code === 200){
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.dialogAdd = !this.dialogAdd;
                                        this.resetForm();
                                        this.getList();
                                    }
                                });
                            } else {
                                this.$message.warning(res.message)
                            }
                        })
                    }
                })
            },
            //创建公告
            addData(){
                this.dialogAdd = !this.dialogAdd;
                this.TitleType = '创建平台公告';
                this.distinguishBtn = 'add'
            },
            // 编辑
            editData(row){
                this.$http.axiosGetBy(this.$api.pa_see, { id:row.id }, res=>{
                    if(res.code === 200){
                        this.dialogAdd = !this.dialogAdd;
                        this.TitleType = '编辑平台公告';
                        this.distinguishBtn = 'edit';
                        this.formData.id = row.id;
                        this.formData.title = res.data.title;
                        this.formData.content = res.data.content;
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            },
            // 查看
            lookData(row){
                this.$http.axiosGetBy(this.$api.pa_see, { id:row.id }, res=>{
                    if(res.code === 200){
                        this.dialogLook = true;
                        this.lookTitle = res.data.title;
                        this.lookContent = res.data.content;
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            },
            // 删除
            deleData(row){
              this.$confirm('是否删除该公告，删除完将无法恢复', '提示消息', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(()=>{
                  this.$http.axiosGetBy(this.$api.pa_del, { id: row.id }, res=>{
                      if(res.code === 200){
                          this.$message({
                              message:res.message,
                              type: "success",
                              duration:1000,
                              onClose:()=>{
                                  this.getList()
                              }
                          })
                      } else {
                          this.$message.warning(res.message)
                      }
                  })
              }).catch(() => {
                  this.$message.info('已取消删除');
              })
            },
            //重置表单
            resetForm() {
                this.$refs.formData.resetFields();
                this.formData = {
                    id:'',
                    title: '',
                    content: ''
                }
            },
            //打开弹窗聚焦输入框
            openAddClassifies() {
                this.$refs.titleInput.focus();
            },
            tableDataCurrentChange(val){
                this.tableDataPages.currentPageNum = val;
                this.getList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .top-right{
        .addBtn{
        }
    }
    .bulletin-box{
        display: flex;
        flex-direction: column;
        .bulletin-title,.bulletin-content{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #333333;
            span{
                line-height: 20px;
                &:nth-child(1){
                    color: #999999;
                }
                &:nth-child(2){
                    margin-top: 8px;
                }
            }
        }
        .bulletin-content{
            margin-top: 14px;
        }
    }
</style>